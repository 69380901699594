export class SetSystemLoadingStatus {
  static readonly type = '[System] Set Global Application Loading Status';
  constructor(
    public isLoading: boolean,
    public loadingMessage: string,
  ) {}
}

export class SetSystemFirebaseIdToken {
  static readonly type = '[System] Set Global Application Firebase Id Token';
  constructor(
    public firebaseIdToken: string
  ) {}
}

export class SetSystemAuthState {
  static readonly type = '[System] Set Global Application Authentication State';
  constructor(
    public isAuthenticated: boolean
  ) {}
}

export class SetSystemLocalVersion {
  static readonly type = '[System] Set System Local Version';
  constructor(
    public localVersion: string
  ) {}
}

export class SetSystemRemoteVersionInfoAndroid {
  static readonly type = '[System] Set System Remote Version Info Android';
  constructor(
    public remoteAndroidVersion: string,
    public androidStoreURL: string
  ) {}
}

export class SetSystemRemoteVersionInfoiOS {
  static readonly type = '[System] Set System Remote Version Info iOS';
  constructor(
    public remoteiOSVersion: string,
    public iOSStoreURL: string
  ) {}
}

export class SetSystemRemoteChangelog {
  static readonly type = '[System] Set System Remote Changelog';
  constructor(
    public remoteChangelog: string
  ) {}
}

export class SetOnlineStatus {
  static readonly type = '[System] Set Online Status';
  constructor(
    public online: boolean
  ) {}
}

export class SetRefererUrl {
  static readonly type = '[System] Set Referer Url';
  constructor(
    public refererUrl: string
  ) {}
}

export class SetNativeMobile {
  static readonly type = '[System] Set Native Mobile';
  constructor(
    public isNativeMobile: boolean
  ) {}
}
