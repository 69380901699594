import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as auth0 from 'auth0-js';
import Auth0Cordova from '@auth0/cordova';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { ApiService } from '@docentecas/app/services/api/api.service';
import { FirebaseService } from '@docentecas/app/services/firebase/firebase.service';
import { AlertsService } from '@docentecas/app/services/alerts/alerts.service';
import { StorageService } from '@docentecas/app/services/storage/storage.service';

// NGXS
import { Store, Select } from '@ngxs/store';
import {
  SetSystemAuthState,
  SetSystemLoadingStatus
} from '@docentecas/app/store/system/system.actions';
import { SystemState } from '@docentecas/app/store/system/system.state';
import { SystemStateModel } from '@docentecas/app/store/system/system.model';
import { SetUserInfo } from '@docentecas/app/store/user/user.actions';

import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {

  public localSubscription: Subscription;

  constructor(
    private store: Store,
    private alerts: AlertsService,
    private api: ApiService,
    private firebase: FirebaseService,
    private storage: StorageService,
    private router: Router,
    private platform: Platform,
  ) { }

  public webAuth: any = new auth0.WebAuth({
    domain: environment.auth0.domain,
    clientID: environment.auth0.clientID,
    audience: environment.auth0.audience,
    responseType: environment.auth0.responseType,
    connection: environment.auth0.connection,
    scope: environment.auth0.scope
  });

  public nativeAuth = new Auth0Cordova({
    clientID: environment.auth0.clientID,
    clientId: environment.auth0.clientID,
    domain: environment.auth0.domain,
    connection: environment.auth0.connection,
    packageIdentifier: 'cl.alemana.docencas',
    scope: environment.auth0.scope,
  });

  /**
   * Redirige al dominio de autenticación de Auth0 (web o nativo)
   *
   * @return void
   */
  public login(redirectUri) {
    const isNativeMobile = this.store.selectSnapshot(SystemState.isNativeMobile);
    if (isNativeMobile) {
      // Cordova-Based Auth0
      const options = {
        scope: 'openid profile email offline_access'
      };
      this.nativeAuth.authorize(options, (err, authResult) => {
        this.store.dispatch(new SetSystemLoadingStatus(true, 'Autenticando...'));
        if (err) {
          console.error('ERROR', err);
          this.alerts.hideLoading('login');
          this.alerts.createToast('Occurrió un error al obtener la información de un usuario en base al access_token');
        }

        const { accessToken } = authResult;
        this.getUserInfo(accessToken, (error, info) => this.parseLoginResultWeb(error, info, accessToken));
      });
    } else {
      // Web-based Auth0
      this.webAuth.authorize({
        redirectUri: redirectUri,
        connection: environment.auth0.connection
      });
    }
  }

  /**
   * Realiza logout desde Auth0
   *
   * @return void
   */
  public logout() {
    this.webAuth.logout({
      returnTo: environment.returnUrl,
      clientID: environment.auth0.clientID
    });
  }

  /**
   * Obtiene la información de un usuario
   *
   * @param accessToken Access Token desde Auth0
   * @param callback Función callback que se ejecuta cuando obtiene la información del usuario
   * @return Retorna un callback información del usuario o un error
   */
  public getUserInfo(accessToken, callback) {
    return this.webAuth.client.userInfo(accessToken, callback);
  }

  /**
   * Parsea la URL que trae AUTH0 tras una autenticación exitosa (WEB)
   *
   * @param callback Función callback que se ejecuta tras una autenticación exitosa
   * @return Retorna un callback con los pasos para manejar la autenticación
   */
  public parseHash(callback) {
    return this.webAuth.parseHash(callback);
  }

  /**
   * Parsea el resultado de login de AUTH0 (WEB)
   *
   * @param callback Función callback que se ejecuta tras una autenticación exitosa
   * @return void
   */
  public parseLoginResultWeb(getUserError, info, access_token) {
    if (getUserError) {
      this.alerts.createToast('Occurrió un error al obtener la información de un usuario en base al access_token');
    } else {
      // Obtiene un token personalizado desde Auth0
      this.localSubscription = this.api.getCustomToken(access_token).subscribe((response) => {
        const auth0Id = response.mapTo;
        const fbCustomSignInToken = response.fbCustomToken;

        // Autenticación en Firebase
        this.firebase.firebaseCustomSignIn(fbCustomSignInToken).then((loginResult) => {
          // Guarda la información de la sesión
          this.store.dispatch(new SetSystemAuthState(true));
          this.store.dispatch(
            new SetUserInfo(
              auth0Id,
              info.given_name,
              info.family_name,
              info.nickname.split('|')[0],
              info.email.split('|')[0],
              (info.email.split('|')[1]) ? info.email.split('|')[1] : 'SIN ÁREA',
              info.nickname.split('|')[1],
              info.picture
            )
          );
          this.storage.saveUser(
            info.given_name,
            info.family_name,
            info.nickname.split('|')[0],
            auth0Id,
            info.email.split('|')[0],
            (info.email.split('|')[1]) ? info.email.split('|')[1] : 'SIN ÁREA',
            info.nickname.split('|')[1],
            info.picture
          );

          // Actualiza la información del usuario
          const user = {
            depto: (info.email.split('|')[1]) ? info.email.split('|')[1] : 'SIN ÁREA',
            email: info.email.split('|')[0],
            family_name: info.family_name,
            given_name: info.given_name,
            nickname: info.nickname.split('|')[0],
            picture: info.picture,
            rut: info.nickname.split('|')[1],
            sub: info.sub,
            updated_at: info.updated_at,
          };
          this.firebase.updatePeopleData(user).then(() => {
            if (this.platform.is('cordova')) {
              // Navega directamente a la lista de reuniones
              this.alerts.hideLoading('login');
              this.router.navigateByUrl('/meetings-list');
            } else {
              // Pregunta si el usuario tiene  permisos para geolocalizarse
              if (navigator['permissions']) {
                navigator['permissions'].query({ name: 'geolocation' }).then((permission) => {
                  this.alerts.hideLoading('login');
                  this.store.dispatch(new SetSystemLoadingStatus(false, ''));
                  if (permission.state === 'denied' || permission.state === 'prompt') {
                    this.router.navigateByUrl('/enable-geolocation');
                  } else {
                    this.router.navigateByUrl('/meetings-list');
                  }
                }, (error) => {
                  this.alerts.hideLoading('login');
                  this.alerts.createToast('Ocurrió un error al consultar los permisos de navegación');
                  console.error('ERROR', error);
                });
              } else {
                this.alerts.hideLoading('login');
                this.store.dispatch(new SetSystemLoadingStatus(false, ''));
                this.router.navigateByUrl('/meetings-list');
              }
            }
          }, (error) => {
            this.alerts.createToast('Ocurrió un error al actualizar la información del usuario');
            console.error('ERROR', error);
          });
        }, (error) => {
          this.alerts.createToast('Ocurrió un error al autenticarse en la base de datos');
          console.error('ERROR', error);
        });
      }, (error) => {
        this.alerts.hideLoading('login');
        this.alerts.createToast('Error al obtener un token de autorización');
      });
    }
  }

  public parseLoginResultNative() {

  }
}
