import * as tslib_1 from "tslib";
import { State, Selector, Action } from '@ngxs/store';
import { AddPresentator } from './presentators.actions';
var PresentatorsState = /** @class */ (function () {
    function PresentatorsState() {
    }
    // Obtiene todos los presentadores
    PresentatorsState.presentators = function (state) { return state; };
    // Agrega un nuevo presentador a la lista
    PresentatorsState.prototype.AddPresentator = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var action = _b.action;
        var state = getState();
        patchState({
            presentators: state.presentators.concat([action])
        });
    };
    tslib_1.__decorate([
        Action(AddPresentator),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddPresentator]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PresentatorsState.prototype, "AddPresentator", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PresentatorsState, "presentators", null);
    PresentatorsState = tslib_1.__decorate([
        State({
            name: 'presentators',
            defaults: {
                presentators: [],
            }
        })
    ], PresentatorsState);
    return PresentatorsState;
}());
export { PresentatorsState };
