import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { SetSystemLoadingStatus } from '@docentecas/app/store/system/system.actions';
import { SystemState } from '@docentecas/app/store/system/system.state';

import { Observable } from 'rxjs';
import { SystemStateModel } from '@docentecas/app/store/system/system.model';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  public system;
  @Select(state => state.system) $system: Observable<SystemStateModel>;

  constructor(
    private store: Store
  ) {
    this.$system.subscribe((state) => {
      this.system = state;
    });
  }

  ngOnInit() {}

}
