import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { PresentatorsStateModel } from './presentators.model';
import {
  AddPresentator
} from './presentators.actions';

@State<PresentatorsStateModel>({
  name: 'presentators',
  defaults: {
    presentators: [],
  }
})
export class PresentatorsState {

  // Obtiene todos los presentadores
  @Selector()
  static presentators(state: PresentatorsStateModel ) { return state; }

  // Agrega un nuevo presentador a la lista
  @Action(AddPresentator)
  AddPresentator({ getState, patchState }: StateContext<PresentatorsStateModel>, { action }: AddPresentator) {
    const state = getState();
    patchState({
      presentators:  [...state.presentators, action]
    });
  }
}
