import * as tslib_1 from "tslib";
import { State, Selector, Action } from '@ngxs/store';
import { AddNew } from './news.actions';
var NewsState = /** @class */ (function () {
    function NewsState() {
    }
    // Obtiene la noticia actual
    NewsState.presentators = function (state) { return state; };
    // Agrega una noticia al state
    NewsState.prototype.AddNew = function (_a, _b) {
        var patchState = _a.patchState;
        var action = _b.action;
        patchState(action);
    };
    tslib_1.__decorate([
        Action(AddNew),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddNew]),
        tslib_1.__metadata("design:returntype", void 0)
    ], NewsState.prototype, "AddNew", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], NewsState, "presentators", null);
    NewsState = tslib_1.__decorate([
        State({
            name: 'news',
            defaults: {
                id: '',
                extracto: '',
                fecha: '',
                fechashort: 0,
                imagen: '',
                texto: '',
                titulo: '',
                url_noticia: '',
            }
        })
    ], NewsState);
    return NewsState;
}());
export { NewsState };
