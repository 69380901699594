import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { AchievementsStateModel } from './achievements.model';
import {
  AddActualYearAchievements,
  AddTemporalAchievement,
} from './achievements.actions';

@State<AchievementsStateModel>({
  name: 'achievements',
  defaults: {
    aquiredAchievements: [],
    myAchievements: [],
    actualYearAchievements: [],
  }
})
export class AchievementsState {

  // Obtiene todos mis logros
  @Selector()
  static myAchievements(state: AchievementsStateModel ) { return state.myAchievements; }

  @Selector()
  static actualYearAchievements(state: AchievementsStateModel ) { return state.actualYearAchievements; }

  @Selector()
  static aquiredAchievements(state: AchievementsStateModel ) { return state.aquiredAchievements; }

  // Agrega un nuevo logro a la lista de logros totales del año
  @Action(AddActualYearAchievements)
  AddActualYearAchievements({ getState, patchState }: StateContext<AchievementsStateModel>, { action }: AddActualYearAchievements) {
    const state = getState();
    patchState({
      actualYearAchievements:  [...state.actualYearAchievements, action]
    });
  }

  // Agrega un nuevo logro a la lista de logros temporales adquiridos (al momento de marcar una reunión)
  @Action(AddTemporalAchievement)
  AddTemporalAchievement({ getState, patchState }: StateContext<AchievementsStateModel>, { action }: AddTemporalAchievement) {
    const state = getState();
    patchState({
      aquiredAchievements:  [...state.aquiredAchievements, action]
    });
  }
}
