import { environment } from '../../../environments/environment';
import * as auth0 from 'auth0-js';
import Auth0Cordova from '@auth0/cordova';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ApiService } from '@docentecas/app/services/api/api.service';
import { FirebaseService } from '@docentecas/app/services/firebase/firebase.service';
import { AlertsService } from '@docentecas/app/services/alerts/alerts.service';
import { StorageService } from '@docentecas/app/services/storage/storage.service';
// NGXS
import { Store } from '@ngxs/store';
import { SetSystemAuthState, SetSystemLoadingStatus } from '@docentecas/app/store/system/system.actions';
import { SystemState } from '@docentecas/app/store/system/system.state';
import { SetUserInfo } from '@docentecas/app/store/user/user.actions';
import { Subscription } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "../alerts/alerts.service";
import * as i3 from "../api/api.service";
import * as i4 from "../firebase/firebase.service";
import * as i5 from "../storage/storage.service";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/angular";
var Auth0Service = /** @class */ (function () {
    function Auth0Service(store, alerts, api, firebase, storage, router, platform) {
        this.store = store;
        this.alerts = alerts;
        this.api = api;
        this.firebase = firebase;
        this.storage = storage;
        this.router = router;
        this.platform = platform;
        this.webAuth = new auth0.WebAuth({
            domain: environment.auth0.domain,
            clientID: environment.auth0.clientID,
            audience: environment.auth0.audience,
            responseType: environment.auth0.responseType,
            connection: environment.auth0.connection,
            scope: environment.auth0.scope
        });
        this.nativeAuth = new Auth0Cordova({
            clientID: environment.auth0.clientID,
            clientId: environment.auth0.clientID,
            domain: environment.auth0.domain,
            connection: environment.auth0.connection,
            packageIdentifier: 'cl.alemana.docencas',
            scope: environment.auth0.scope,
        });
    }
    /**
     * Redirige al dominio de autenticación de Auth0 (web o nativo)
     *
     * @return void
     */
    Auth0Service.prototype.login = function (redirectUri) {
        var _this = this;
        var isNativeMobile = this.store.selectSnapshot(SystemState.isNativeMobile);
        if (isNativeMobile) {
            // Cordova-Based Auth0
            var options = {
                scope: 'openid profile email offline_access'
            };
            this.nativeAuth.authorize(options, function (err, authResult) {
                _this.store.dispatch(new SetSystemLoadingStatus(true, 'Autenticando...'));
                if (err) {
                    console.error('ERROR', err);
                    _this.alerts.hideLoading('login');
                    _this.alerts.createToast('Occurrió un error al obtener la información de un usuario en base al access_token');
                }
                var accessToken = authResult.accessToken;
                _this.getUserInfo(accessToken, function (error, info) { return _this.parseLoginResultWeb(error, info, accessToken); });
            });
        }
        else {
            // Web-based Auth0
            this.webAuth.authorize({
                redirectUri: redirectUri,
                connection: environment.auth0.connection
            });
        }
    };
    /**
     * Realiza logout desde Auth0
     *
     * @return void
     */
    Auth0Service.prototype.logout = function () {
        this.webAuth.logout({
            returnTo: environment.returnUrl,
            clientID: environment.auth0.clientID
        });
    };
    /**
     * Obtiene la información de un usuario
     *
     * @param accessToken Access Token desde Auth0
     * @param callback Función callback que se ejecuta cuando obtiene la información del usuario
     * @return Retorna un callback información del usuario o un error
     */
    Auth0Service.prototype.getUserInfo = function (accessToken, callback) {
        return this.webAuth.client.userInfo(accessToken, callback);
    };
    /**
     * Parsea la URL que trae AUTH0 tras una autenticación exitosa (WEB)
     *
     * @param callback Función callback que se ejecuta tras una autenticación exitosa
     * @return Retorna un callback con los pasos para manejar la autenticación
     */
    Auth0Service.prototype.parseHash = function (callback) {
        return this.webAuth.parseHash(callback);
    };
    /**
     * Parsea el resultado de login de AUTH0 (WEB)
     *
     * @param callback Función callback que se ejecuta tras una autenticación exitosa
     * @return void
     */
    Auth0Service.prototype.parseLoginResultWeb = function (getUserError, info, access_token) {
        var _this = this;
        if (getUserError) {
            this.alerts.createToast('Occurrió un error al obtener la información de un usuario en base al access_token');
        }
        else {
            // Obtiene un token personalizado desde Auth0
            this.localSubscription = this.api.getCustomToken(access_token).subscribe(function (response) {
                var auth0Id = response.mapTo;
                var fbCustomSignInToken = response.fbCustomToken;
                // Autenticación en Firebase
                _this.firebase.firebaseCustomSignIn(fbCustomSignInToken).then(function (loginResult) {
                    // Guarda la información de la sesión
                    _this.store.dispatch(new SetSystemAuthState(true));
                    _this.store.dispatch(new SetUserInfo(auth0Id, info.given_name, info.family_name, info.nickname.split('|')[0], info.email.split('|')[0], (info.email.split('|')[1]) ? info.email.split('|')[1] : 'SIN ÁREA', info.nickname.split('|')[1], info.picture));
                    _this.storage.saveUser(info.given_name, info.family_name, info.nickname.split('|')[0], auth0Id, info.email.split('|')[0], (info.email.split('|')[1]) ? info.email.split('|')[1] : 'SIN ÁREA', info.nickname.split('|')[1], info.picture);
                    // Actualiza la información del usuario
                    var user = {
                        depto: (info.email.split('|')[1]) ? info.email.split('|')[1] : 'SIN ÁREA',
                        email: info.email.split('|')[0],
                        family_name: info.family_name,
                        given_name: info.given_name,
                        nickname: info.nickname.split('|')[0],
                        picture: info.picture,
                        rut: info.nickname.split('|')[1],
                        sub: info.sub,
                        updated_at: info.updated_at,
                    };
                    _this.firebase.updatePeopleData(user).then(function () {
                        if (_this.platform.is('cordova')) {
                            // Navega directamente a la lista de reuniones
                            _this.alerts.hideLoading('login');
                            _this.router.navigateByUrl('/meetings-list');
                        }
                        else {
                            // Pregunta si el usuario tiene  permisos para geolocalizarse
                            if (navigator['permissions']) {
                                navigator['permissions'].query({ name: 'geolocation' }).then(function (permission) {
                                    _this.alerts.hideLoading('login');
                                    _this.store.dispatch(new SetSystemLoadingStatus(false, ''));
                                    if (permission.state === 'denied' || permission.state === 'prompt') {
                                        _this.router.navigateByUrl('/enable-geolocation');
                                    }
                                    else {
                                        _this.router.navigateByUrl('/meetings-list');
                                    }
                                }, function (error) {
                                    _this.alerts.hideLoading('login');
                                    _this.alerts.createToast('Ocurrió un error al consultar los permisos de navegación');
                                    console.error('ERROR', error);
                                });
                            }
                            else {
                                _this.alerts.hideLoading('login');
                                _this.store.dispatch(new SetSystemLoadingStatus(false, ''));
                                _this.router.navigateByUrl('/meetings-list');
                            }
                        }
                    }, function (error) {
                        _this.alerts.createToast('Ocurrió un error al actualizar la información del usuario');
                        console.error('ERROR', error);
                    });
                }, function (error) {
                    _this.alerts.createToast('Ocurrió un error al autenticarse en la base de datos');
                    console.error('ERROR', error);
                });
            }, function (error) {
                _this.alerts.hideLoading('login');
                _this.alerts.createToast('Error al obtener un token de autorización');
            });
        }
    };
    Auth0Service.prototype.parseLoginResultNative = function () {
    };
    Auth0Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Auth0Service_Factory() { return new Auth0Service(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.AlertsService), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i4.FirebaseService), i0.ɵɵinject(i5.StorageService), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i7.Platform)); }, token: Auth0Service, providedIn: "root" });
    return Auth0Service;
}());
export { Auth0Service };
