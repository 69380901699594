import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { AttendancesStateModel } from './attendances.model';
import {
  AddActiveMeetingAttendance
} from './attendances.actions';

@State<AttendancesStateModel>({
  name: 'attendances',
  defaults: {
    myAttendances: [],
    activeMeetingAttendances: [],
  }
})
export class AttendancesState {

  // Obtiene todos mis logros
  @Selector()
  static myAttendances(state: AttendancesStateModel ) { return state.myAttendances; }

  @Selector()
  static activeMeetingAttendances(state: AttendancesStateModel ) { return state.activeMeetingAttendances; }

  // Agrega un asistente a la reunión actual activa
  @Action(AddActiveMeetingAttendance)
  AddActiveMeetingAttendance({ getState, patchState }: StateContext<AttendancesStateModel>, { action }: AddActiveMeetingAttendance) {
    const state = getState();
    patchState({
      activeMeetingAttendances:  [...state.activeMeetingAttendances, action]
    });
  }
}
