import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
var ɵ0 = function (state) { return state.system; };
var LoaderComponent = /** @class */ (function () {
    function LoaderComponent(store) {
        var _this = this;
        this.store = store;
        this.$system.subscribe(function (state) {
            _this.system = state;
        });
    }
    LoaderComponent.prototype.ngOnInit = function () { };
    tslib_1.__decorate([
        Select(ɵ0),
        tslib_1.__metadata("design:type", Observable)
    ], LoaderComponent.prototype, "$system", void 0);
    return LoaderComponent;
}());
export { LoaderComponent };
export { ɵ0 };
