import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var AlertsService = /** @class */ (function () {
    function AlertsService(loading, toast) {
        this.loading = loading;
        this.toast = toast;
        this.isLoading = false;
    }
    // Crea un spinner con un mensaje y lo muestra
    AlertsService.prototype.showLoading = function (loadingId, message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hasLoading, loading;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loading.getTop()];
                    case 1:
                        hasLoading = _a.sent();
                        if (!!hasLoading) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.loading.create({
                                id: loadingId,
                                message: message,
                                translucent: true,
                                spinner: 'dots'
                            })];
                    case 2:
                        loading = _a.sent();
                        return [4 /*yield*/, loading.present()];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Oculta un spinnet y lo muestra
    AlertsService.prototype.hideLoading = function (loadingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loading.dismiss(null, null, loadingId).then(function () { return console.log('loading dismissed'); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AlertsService.prototype.createToast = function (message, duration) {
        if (message === void 0) { message = ''; }
        if (duration === void 0) { duration = 3000; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toast.create({
                            message: message,
                            duration: duration,
                        })];
                    case 1:
                        toast = _a.sent();
                        return [2 /*return*/, toast.present()];
                }
            });
        });
    };
    AlertsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertsService_Factory() { return new AlertsService(i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.ToastController)); }, token: AlertsService, providedIn: "root" });
    return AlertsService;
}());
export { AlertsService };
