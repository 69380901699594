import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './services/auth-guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'meetings-list', loadChildren: './pages/meetings-list/meetings-list.module#MeetingsListPageModule', canActivate: [AuthGuard] },
  { path: 'meeting', loadChildren: './pages/meeting-tabs/meeting-tabs.module#MeetingTabsPageModule', canActivate: [AuthGuard] },
  { path: 'attendance', loadChildren: './pages/attendance/attendance.module#AttendancePageModule', canActivate: [AuthGuard] },
  {
    path: 'meeting-files',
    loadChildren: './pages/meeting-tabs/meeting-files/meeting-files.module#MeetingFilesPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'achievement-detail',
    loadChildren: './pages/achievement-detail/achievement-detail.module#AchievementDetailPageModule',
    canActivate: [AuthGuard]
  },
  { path: 'achievements', loadChildren: './pages/achievements/achievements.module#AchievementsPageModule', canActivate: [AuthGuard] },
  { path: 'aquired-achievements', loadChildren: './pages/aquired-achievements/aquired-achievements.module#AquiredAchievementsPageModule' },
  {
    path: 'rating',
    loadChildren: './pages/meeting-tabs/meeting-rating/meeting-rating.module#MeetingRatingPageModule',
    canActivate: [AuthGuard]
  },
  { path: 'news', loadChildren: './pages/news/news.module#NewsPageModule', canActivate: [AuthGuard] },
  { path: 'news-detail', loadChildren: './pages/news-detail/news-detail.module#NewsDetailPageModule' },
  {
    path: 'configurations',
    loadChildren: './pages/configurations/configurations.module#ConfigurationsPageModule',
    canActivate: [AuthGuard]
  },
  { path: 'enable-geolocation', loadChildren: './pages/enable-geolocation/enable-geolocation.module#EnableGeolocationPageModule' },
  { path: 'connectivity', loadChildren: './pages/connectivity/connectivity.module#ConnectivityPageModule' },
  { path: 'update', loadChildren: './pages/update/update.module#UpdatePageModule' },
  { path: 'not-found', loadChildren: './pages/not-found/not-found.module#NotFoundPageModule' },
  { path: '**', redirectTo: 'not-found'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
