import * as tslib_1 from "tslib";
import { State, Selector, Action } from '@ngxs/store';
import { AddActiveMeetingAttendance } from './attendances.actions';
var AttendancesState = /** @class */ (function () {
    function AttendancesState() {
    }
    // Obtiene todos mis logros
    AttendancesState.myAttendances = function (state) { return state.myAttendances; };
    AttendancesState.activeMeetingAttendances = function (state) { return state.activeMeetingAttendances; };
    // Agrega un asistente a la reunión actual activa
    AttendancesState.prototype.AddActiveMeetingAttendance = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var action = _b.action;
        var state = getState();
        patchState({
            activeMeetingAttendances: state.activeMeetingAttendances.concat([action])
        });
    };
    tslib_1.__decorate([
        Action(AddActiveMeetingAttendance),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddActiveMeetingAttendance]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AttendancesState.prototype, "AddActiveMeetingAttendance", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AttendancesState, "myAttendances", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AttendancesState, "activeMeetingAttendances", null);
    AttendancesState = tslib_1.__decorate([
        State({
            name: 'attendances',
            defaults: {
                myAttendances: [],
                activeMeetingAttendances: [],
            }
        })
    ], AttendancesState);
    return AttendancesState;
}());
export { AttendancesState };
