import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { UserStateModel } from './user.model';
import {
  SetUserInfo,
  SetUserAttendancesInCurrentYear
} from './user.actions';

@State({
  name: 'user',
  defaults: {
    id: '',
    given_name: '',
    family_name: '',
    nickname: '',
    email: '',
    department: '',
    rut: '',
    avatar: '',
    n_attendances: 0,
  }
})
export class UserState {
  @Selector()
  static userInfo(state: UserStateModel) { return state; }

  @Selector()
  static userNickname(state: UserStateModel) { return state.nickname; }

  @Selector()
  static nAttendances(state: UserStateModel) { return state.n_attendances; }

  constructor(private store: Store) {}

  // Setea la información del usuario
  @Action(SetUserInfo)
  SetUserInfo(stateContext: StateContext<UserStateModel>, action: SetUserInfo) {
    stateContext.patchState({
      id: action.id,
      given_name: action.given_name,
      family_name: action.family_name,
      nickname: action.nickname,
      email: action.email,
      department: action.department,
      rut: action.rut,
      avatar: action.avatar
    });
  }

  // Setea la cantidad de asistencias del usuario actual en el año
  @Action(SetUserAttendancesInCurrentYear)
  SetUserAttendancesInCurrentYear(stateContext: StateContext<UserStateModel>, action: SetUserAttendancesInCurrentYear) {
    stateContext.patchState({
      n_attendances: action.n_attendances
    });
  }
}
