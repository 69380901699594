import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;

  constructor() {}

  public online() {
    return this.onlineEvent = fromEvent(window, 'online');
  }

  public offline() {
    return this.offlineEvent = fromEvent(window, 'offline');
  }
}
