import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    // Guarda en información de sesión los datos del usuario
    StorageService.prototype.saveUser = function (given_name, family_name, nickname, user_id, email, department, rut, avatar) {
        return sessionStorage.setItem('user', JSON.stringify({
            given_name: given_name,
            family_name: family_name,
            nickname: nickname,
            user_id: user_id,
            email: email,
            department: department,
            rut: rut,
            avatar: avatar
        }));
    };
    // Obtiene los datos de sesión del usuario
    StorageService.prototype.getUser = function () {
        if (sessionStorage.getItem('user') == null) {
            return null;
        }
        return JSON.parse(sessionStorage.getItem('user'));
    };
    // Elimina los datos de sessión
    StorageService.prototype.clearSession = function () {
        return sessionStorage.clear();
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
