import * as tslib_1 from "tslib";
import { CanActivate, Router } from '@angular/router';
// NGXS
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var ɵ0 = function (state) { return state.system; };
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router) {
        var _this = this;
        this.router = router;
        // Se suscribe a los cambios de estado
        this.system$.subscribe(function (system) {
            _this.system = system;
        });
    }
    AuthGuard.prototype.canActivate = function () {
        if (this.system.isAuthenticated) {
            return true;
        }
        else {
            this.router.navigate(['/login']);
        }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router)); }, token: AuthGuard, providedIn: "root" });
    tslib_1.__decorate([
        Select(ɵ0),
        tslib_1.__metadata("design:type", Observable)
    ], AuthGuard.prototype, "system$", void 0);
    return AuthGuard;
}());
export { AuthGuard };
export { ɵ0 };
