import * as tslib_1 from "tslib";
import { State, Selector, Store, Action } from '@ngxs/store';
import { SetUserInfo, SetUserAttendancesInCurrentYear } from './user.actions';
var UserState = /** @class */ (function () {
    function UserState(store) {
        this.store = store;
    }
    UserState.userInfo = function (state) { return state; };
    UserState.userNickname = function (state) { return state.nickname; };
    UserState.nAttendances = function (state) { return state.n_attendances; };
    // Setea la información del usuario
    UserState.prototype.SetUserInfo = function (stateContext, action) {
        stateContext.patchState({
            id: action.id,
            given_name: action.given_name,
            family_name: action.family_name,
            nickname: action.nickname,
            email: action.email,
            department: action.department,
            rut: action.rut,
            avatar: action.avatar
        });
    };
    // Setea la cantidad de asistencias del usuario actual en el año
    UserState.prototype.SetUserAttendancesInCurrentYear = function (stateContext, action) {
        stateContext.patchState({
            n_attendances: action.n_attendances
        });
    };
    tslib_1.__decorate([
        Action(SetUserInfo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetUserInfo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "SetUserInfo", null);
    tslib_1.__decorate([
        Action(SetUserAttendancesInCurrentYear),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetUserAttendancesInCurrentYear]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "SetUserAttendancesInCurrentYear", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "userInfo", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "userNickname", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "nAttendances", null);
    UserState = tslib_1.__decorate([
        State({
            name: 'user',
            defaults: {
                id: '',
                given_name: '',
                family_name: '',
                nickname: '',
                email: '',
                department: '',
                rut: '',
                avatar: '',
                n_attendances: 0,
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store])
    ], UserState);
    return UserState;
}());
export { UserState };
