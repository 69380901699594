import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';

// NGXS
import { Select } from '@ngxs/store';
import { SystemStateModel } from '@docentecas/app/store/system/system.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {
  @Select(state => state.system) system$: Observable<SystemStateModel>;
  public system;

  constructor(
    private router: Router
  ) {
    // Se suscribe a los cambios de estado
    this.system$.subscribe((system) => {
      this.system = system;
    });
  }

  canActivate(): boolean {
    if (this.system.isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }
}
