import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  // Guarda en información de sesión los datos del usuario
  public saveUser(
    given_name: string,
    family_name: string,
    nickname: string,
    user_id: string,
    email: string,
    department: string,
    rut: string,
    avatar: string
  ) {
    return sessionStorage.setItem('user', JSON.stringify({
      given_name: given_name,
      family_name: family_name,
      nickname: nickname,
      user_id: user_id,
      email: email,
      department: department,
      rut: rut,
      avatar: avatar
    }));
  }

  // Obtiene los datos de sesión del usuario
  public getUser(): IUserStorageInfo | null {
    if (sessionStorage.getItem('user') == null) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem('user'));
  }

  // Elimina los datos de sessión
  public clearSession() {
    return sessionStorage.clear();
  }
}

// INTERFACES
export interface IUserStorageInfo {
  token: string;
  name: string;
  lastname_f: string;
  lastname_m: string;
  user_id: string;
  email: string;
  department: string;
  rut: string;
  avatar: string;
}
