import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  public isLoading = false;

  constructor(
    private loading: LoadingController,
    private toast: ToastController
  ) { }

  // Crea un spinner con un mensaje y lo muestra
  public async showLoading(loadingId, message) {
    const hasLoading = await this.loading.getTop();
    if (!hasLoading) {
      const loading = await this.loading.create({
        id: loadingId,
        message: message,
        translucent: true,
        spinner: 'dots'
      });
      return await loading.present();
    }
  }

  // Oculta un spinnet y lo muestra
  public async hideLoading(loadingId) {
    return await this.loading.dismiss(null, null, loadingId).then(() => console.log('loading dismissed'));
  }

  public async createToast(message = '', duration = 3000) {
    const toast = await this.toast.create({
      message,
      duration,
    });
    return toast.present();
  }
}
