export class SetUserInfo {
  static readonly type = '[User] Ser User Info';
  constructor(
    public id: string,
    public given_name: string,
    public family_name: string,
    public nickname: string,
    public email: string,
    public department: string,
    public rut: string,
    public avatar: string,
  ) {}
}

export class SetUserAttendancesInCurrentYear {
  static readonly type = '[User] Ser User Attendances in current year';
  constructor(
    public n_attendances: number,
  ) {}
}
