<ion-app>
  <app-loader></app-loader>
  
  <ion-split-pane>

    <!-- MENU -->
    <ion-menu type="overlay">
      <ion-header class="menu_header">
        <ion-toolbar color="purple">
          <ion-buttons slot="end">
            <ion-button (click)="closeMenu()">
              <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title><strong>DocenteCAS</strong></ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <!-- Opciones -->
        <div class="user_container">
          <div class="avatar_container">
            <img src="{{ (user$ | async).avatar }}" alt="">
          </div>
          <div class="info_container">
            <h3>{{ (user$ | async).given_name }} {{ (user$ | async).family_name }}</h3>
            <h5>{{ (user$ | async).department }}</h5>
          </div>
        </div>

        <!-- Opciones -->
        <ion-menu-toggle autoHide="false">
          <ion-list class="menuOptions">
              <ion-item lines="none" routerLink="/meetings-list" routerLinkActive="active">
                <div class="icon">
                  <ion-icon name="people"></ion-icon>
                </div>
                <span class="menuTitle">Reuniones</span>
              </ion-item>
              <ion-item lines="none" routerLink="/attendance" routerLinkActive="active">
                <div class="icon">
                  <ion-icon name="list-box"></ion-icon>
                </div>
                <span class="menuTitle">Mis Asistencias</span>
              </ion-item>
              <ion-item lines="none" routerLink="/achievements" routerLinkActive="active">
                <div class="icon">
                  <ion-icon name="trophy"></ion-icon>
                </div>
                <span class="menuTitle">Mis Logros</span>
              </ion-item>
              <ion-item lines="none" routerLink="/news" routerLinkActive="active">
                <div class="icon">
                  <ion-icon name="md-paper"></ion-icon>
                </div>
                <span class="menuTitle">Noticias</span>
              </ion-item>
          </ion-list>
        </ion-menu-toggle>
        

        <div class="version_container">
          <p>{{(system$ | async).version}} DocenteCAS</p>
          <p>App del <strong>Departamento Científico-Docente</strong> de <strong>Clínica Alemana de Santiago</strong></p>
          <ion-button expand="block" class="logout_btn" color="purple" fill="outline" size="large" (click)="logout()">Desconectarse</ion-button>
        </div>
      </ion-content>
    </ion-menu>

    <!-- CONTENIDO -->
    <ion-router-outlet main></ion-router-outlet>
  </ion-split-pane>
</ion-app>
