import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { timer } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
    }
    // Obtiene un token de autenticación custom para Firebase
    ApiService.prototype.getCustomToken = function (access_token) {
        return this.http.get(environment.firebaseFunctionURL + access_token);
    };
    // Obtiene el timestamp del SHOA
    ApiService.prototype.getServerTimestamp = function () {
        return this.http.get(environment.firebaseFunctionEndpoint + 'sync_date');
    };
    // Se suscribe a un topic de notificaciones en particular
    ApiService.prototype.subscribeToTopic = function (token, topic) {
        return this.http.post(environment.firebaseFunctionEndpoint + "subscribe/", { token: token, topic: topic });
    };
    // Se desuscribe desde un topic de notificaciones en particular
    ApiService.prototype.unsubscribeToTopic = function (token, topic) {
        return this.http.post(environment.firebaseFunctionEndpoint + "unsubscribe/", { token: token, topic: topic });
    };
    // Comprueba si el usuario se gana un logro al marcar la asistencia
    ApiService.prototype.checkAchievements = function (nickname) {
        return this.http.post(environment.firebaseFunctionEndpoint + "achievements/", { nickname: nickname });
    };
    // Envía la asistencia al email de un usuario
    ApiService.prototype.sendAttendanceEmail = function (meetingId, nickname) {
        return this.http.get(environment.firebaseFunctionEndpoint + "get_attendance/" + meetingId + "/" + nickname);
    };
    // Inicia el timer
    ApiService.prototype.createTimer = function (initTimer) {
        var _this = this;
        this.serverTimestamp = initTimer;
        this.timer = timer(0, 1000).pipe(map(function (value) {
            return _this.serverTimestamp++;
        }));
    };
    // Calcula Harvesine para (x1,y1) y (x2,y2)
    ApiService.prototype.calculateHarvesine = function (coords1, coords2) {
        var lon1 = coords1[0];
        var lat1 = coords1[1];
        var lon2 = coords2[0];
        var lat2 = coords2[1];
        var R = 6371; // km
        var x1 = lat2 - lat1;
        var dLat = this.toRad(x1);
        var x2 = lon2 - lon1;
        var dLon = this.toRad(x2);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return (d * 1000); // Convert to meters;
    };
    // Convierte ángulos a radianes
    ApiService.prototype.toRad = function (x) {
        return x * Math.PI / 180;
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
