import * as tslib_1 from "tslib";
import { State, Selector, Action } from '@ngxs/store';
import { AddActualYearAchievements, AddTemporalAchievement, } from './achievements.actions';
var AchievementsState = /** @class */ (function () {
    function AchievementsState() {
    }
    // Obtiene todos mis logros
    AchievementsState.myAchievements = function (state) { return state.myAchievements; };
    AchievementsState.actualYearAchievements = function (state) { return state.actualYearAchievements; };
    AchievementsState.aquiredAchievements = function (state) { return state.aquiredAchievements; };
    // Agrega un nuevo logro a la lista de logros totales del año
    AchievementsState.prototype.AddActualYearAchievements = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var action = _b.action;
        var state = getState();
        patchState({
            actualYearAchievements: state.actualYearAchievements.concat([action])
        });
    };
    // Agrega un nuevo logro a la lista de logros temporales adquiridos (al momento de marcar una reunión)
    AchievementsState.prototype.AddTemporalAchievement = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var action = _b.action;
        var state = getState();
        patchState({
            aquiredAchievements: state.aquiredAchievements.concat([action])
        });
    };
    tslib_1.__decorate([
        Action(AddActualYearAchievements),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddActualYearAchievements]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AchievementsState.prototype, "AddActualYearAchievements", null);
    tslib_1.__decorate([
        Action(AddTemporalAchievement),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddTemporalAchievement]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AchievementsState.prototype, "AddTemporalAchievement", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AchievementsState, "myAchievements", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AchievementsState, "actualYearAchievements", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AchievementsState, "aquiredAchievements", null);
    AchievementsState = tslib_1.__decorate([
        State({
            name: 'achievements',
            defaults: {
                aquiredAchievements: [],
                myAchievements: [],
                actualYearAchievements: [],
            }
        })
    ], AchievementsState);
    return AchievementsState;
}());
export { AchievementsState };
