import * as tslib_1 from "tslib";
import { State, Selector, Store, Action } from '@ngxs/store';
import { SetSystemLoadingStatus, SetSystemFirebaseIdToken, SetSystemAuthState, SetSystemLocalVersion, SetSystemRemoteVersionInfoAndroid, SetSystemRemoteVersionInfoiOS, SetSystemRemoteChangelog, SetOnlineStatus, SetRefererUrl, SetNativeMobile } from './system.actions';
var SystemState = /** @class */ (function () {
    function SystemState(store) {
        this.store = store;
    }
    SystemState.isAuthenticated = function (state) { return state.isAuthenticated; };
    SystemState.isNativeMobile = function (state) { return state.isNativeMobile; };
    // Setea el estado de carga de la aplicacion
    SystemState.prototype.SetSystemLoadingStatus = function (stateContext, action) {
        stateContext.patchState({
            isLoading: action.isLoading,
            loadingMessage: action.loadingMessage,
        });
    };
    // Setea el estado de autenticación de la aplicación
    SystemState.prototype.SetSystemAuthState = function (stateContext, action) {
        stateContext.patchState({
            isAuthenticated: action.isAuthenticated
        });
    };
    // Setea el Firebase ID Token
    SystemState.prototype.SetSystemFirebaseIdToken = function (stateContext, action) {
        stateContext.patchState({
            firebaseIdToken: action.firebaseIdToken
        });
    };
    // Setea la versión local de la aplicación
    SystemState.prototype.SetSystemLocalVersion = function (stateContext, action) {
        stateContext.patchState({
            localVersion: action.localVersion
        });
    };
    // Setea la información remota de versión de Android
    SystemState.prototype.SetSystemRemoteVersionInfoAndroid = function (stateContext, action) {
        stateContext.patchState({
            remoteAndroidVersion: action.remoteAndroidVersion,
            androidStoreURL: action.androidStoreURL,
        });
    };
    // Setea la información remota de versión de iOS
    SystemState.prototype.SetSystemRemoteVersionInfoiOS = function (stateContext, action) {
        stateContext.patchState({
            remoteiOSVersion: action.remoteiOSVersion,
            iOSStoreURL: action.iOSStoreURL,
        });
    };
    // Setea la información del changelog
    SystemState.prototype.SetSystemRemoteChangelog = function (stateContext, action) {
        stateContext.patchState({
            remoteChangelog: action.remoteChangelog,
        });
    };
    // Setea el estado de online/offline de la aplicación
    SystemState.prototype.SetOnlineStatus = function (stateContext, action) {
        stateContext.patchState({
            online: action.online
        });
    };
    // Setea la URL de referencia
    SystemState.prototype.SetRefererUrl = function (stateContext, action) {
        stateContext.patchState({
            refererUrl: action.refererUrl
        });
    };
    // Setea si la aplicación está corriendo en un teléfono móvil nativo
    SystemState.prototype.SetNativeMobile = function (stateContext, action) {
        stateContext.patchState({
            isNativeMobile: action.isNativeMobile
        });
    };
    tslib_1.__decorate([
        Action(SetSystemLoadingStatus),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemLoadingStatus]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemLoadingStatus", null);
    tslib_1.__decorate([
        Action(SetSystemAuthState),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemAuthState]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemAuthState", null);
    tslib_1.__decorate([
        Action(SetSystemFirebaseIdToken),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemFirebaseIdToken]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemFirebaseIdToken", null);
    tslib_1.__decorate([
        Action(SetSystemLocalVersion),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemLocalVersion]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemLocalVersion", null);
    tslib_1.__decorate([
        Action(SetSystemRemoteVersionInfoAndroid),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemRemoteVersionInfoAndroid]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemRemoteVersionInfoAndroid", null);
    tslib_1.__decorate([
        Action(SetSystemRemoteVersionInfoiOS),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemRemoteVersionInfoiOS]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemRemoteVersionInfoiOS", null);
    tslib_1.__decorate([
        Action(SetSystemRemoteChangelog),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSystemRemoteChangelog]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetSystemRemoteChangelog", null);
    tslib_1.__decorate([
        Action(SetOnlineStatus),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetOnlineStatus]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetOnlineStatus", null);
    tslib_1.__decorate([
        Action(SetRefererUrl),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetRefererUrl]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetRefererUrl", null);
    tslib_1.__decorate([
        Action(SetNativeMobile),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetNativeMobile]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState.prototype, "SetNativeMobile", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState, "isAuthenticated", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SystemState, "isNativeMobile", null);
    SystemState = tslib_1.__decorate([
        State({
            name: 'system',
            defaults: {
                isLoading: false,
                loadingMessage: '',
                isError: false,
                isNativeMobile: false,
                isAuthenticated: false,
                firebaseIdToken: '',
                localVersion: '',
                remoteAndroidVersion: '',
                remoteiOSVersion: '',
                remoteChangelog: '',
                androidStoreURL: '',
                iOSStoreURL: '',
                online: true,
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store])
    ], SystemState);
    return SystemState;
}());
export { SystemState };
