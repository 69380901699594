import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { timer, Observable } from 'rxjs';
import * as moment from 'moment';

// Interfaces
import { IServerTimestamp } from '@models/server-timestamp';
import { ICustomToken } from '@models/custom-token';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public timer: Observable<Number>;
  private serverTimestamp: number;

  constructor (
    private http: HttpClient
  ) { }

  // Obtiene un token de autenticación custom para Firebase
  public getCustomToken(access_token: string) {
    return this.http.get<ICustomToken>(environment.firebaseFunctionURL + access_token);
  }

  // Obtiene el timestamp del SHOA
  public getServerTimestamp() {
    return this.http.get<IServerTimestamp>(environment.firebaseFunctionEndpoint + 'sync_date');
  }

  // Se suscribe a un topic de notificaciones en particular
  public subscribeToTopic(token: string, topic: string) {
    return this.http.post(`${environment.firebaseFunctionEndpoint}subscribe/`, { token, topic });
  }

  // Se desuscribe desde un topic de notificaciones en particular
  public unsubscribeToTopic(token: string, topic: string) {
    return this.http.post(`${environment.firebaseFunctionEndpoint}unsubscribe/`, { token, topic });
  }

  // Comprueba si el usuario se gana un logro al marcar la asistencia
  public checkAchievements(nickname: string) {
    return this.http.post(`${environment.firebaseFunctionEndpoint}achievements/`, { nickname });
  }

  // Envía la asistencia al email de un usuario
  public sendAttendanceEmail(meetingId: string, nickname: string) {
    return this.http.get(`${environment.firebaseFunctionEndpoint}get_attendance/${meetingId}/${nickname}`);
  }

  // Inicia el timer
  public createTimer(initTimer) {
    this.serverTimestamp = initTimer;
    this.timer = timer(0, 1000).pipe(
      map((value) => {
        return this.serverTimestamp++;
      })
    );
  }

  // Calcula Harvesine para (x1,y1) y (x2,y2)
  public calculateHarvesine(coords1: number[], coords2: number[]) {
    const lon1 = coords1[0];
    const lat1 = coords1[1];
    const lon2 = coords2[0];
    const lat2 = coords2[1];
    const R = 6371; // km

    const x1 = lat2 - lat1;
    const dLat = this.toRad(x1);

    const x2 = lon2 - lon1;
    const dLon = this.toRad(x2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return (d * 1000); // Convert to meters;
  }

  // Convierte ángulos a radianes
  private toRad(x: number) {
    return x * Math.PI / 180;
  }
}
