import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { SystemStateModel } from './system.model';
import {
  SetSystemLoadingStatus,
  SetSystemFirebaseIdToken,
  SetSystemAuthState,
  SetSystemLocalVersion,
  SetSystemRemoteVersionInfoAndroid,
  SetSystemRemoteVersionInfoiOS,
  SetSystemRemoteChangelog,
  SetOnlineStatus,
  SetRefererUrl,
  SetNativeMobile
} from './system.actions';

@State({
  name: 'system',
  defaults: {
    isLoading: false,
    loadingMessage: '',
    isError: false,
    isNativeMobile: false,
    isAuthenticated: false,
    firebaseIdToken: '',
    localVersion: '',
    remoteAndroidVersion: '',
    remoteiOSVersion: '',
    remoteChangelog: '',
    androidStoreURL: '',
    iOSStoreURL: '',
    online: true,
  }
})
export class SystemState {
  @Selector()
  static isAuthenticated(state: SystemStateModel) { return state.isAuthenticated; }

  @Selector()
  static isNativeMobile(state: SystemStateModel) { return state.isNativeMobile; }

  constructor(private store: Store) {}

  // Setea el estado de carga de la aplicacion
  @Action(SetSystemLoadingStatus)
  SetSystemLoadingStatus(stateContext: StateContext<SystemStateModel>, action: SetSystemLoadingStatus) {
    stateContext.patchState({
      isLoading: action.isLoading,
      loadingMessage: action.loadingMessage,
    });
  }

  // Setea el estado de autenticación de la aplicación
  @Action(SetSystemAuthState)
  SetSystemAuthState(stateContext: StateContext<SystemStateModel>, action: SetSystemAuthState) {
    stateContext.patchState({
      isAuthenticated: action.isAuthenticated
    });
  }

  // Setea el Firebase ID Token
  @Action(SetSystemFirebaseIdToken)
  SetSystemFirebaseIdToken(stateContext: StateContext<SystemStateModel>, action: SetSystemFirebaseIdToken) {
    stateContext.patchState({
      firebaseIdToken: action.firebaseIdToken
    });
  }

  // Setea la versión local de la aplicación
  @Action(SetSystemLocalVersion)
  SetSystemLocalVersion(stateContext: StateContext<SystemStateModel>, action: SetSystemLocalVersion) {
    stateContext.patchState({
      localVersion: action.localVersion
    });
  }

  // Setea la información remota de versión de Android
  @Action(SetSystemRemoteVersionInfoAndroid)
  SetSystemRemoteVersionInfoAndroid(stateContext: StateContext<SystemStateModel>, action: SetSystemRemoteVersionInfoAndroid) {
    stateContext.patchState({
      remoteAndroidVersion: action.remoteAndroidVersion,
      androidStoreURL: action.androidStoreURL,
    });
  }

  // Setea la información remota de versión de iOS
  @Action(SetSystemRemoteVersionInfoiOS)
  SetSystemRemoteVersionInfoiOS(stateContext: StateContext<SystemStateModel>, action: SetSystemRemoteVersionInfoiOS) {
    stateContext.patchState({
      remoteiOSVersion: action.remoteiOSVersion,
      iOSStoreURL: action.iOSStoreURL,
    });
  }

  // Setea la información del changelog
  @Action(SetSystemRemoteChangelog)
  SetSystemRemoteChangelog(stateContext: StateContext<SystemStateModel>, action: SetSystemRemoteChangelog) {
    stateContext.patchState({
      remoteChangelog: action.remoteChangelog,
    });
  }

  // Setea el estado de online/offline de la aplicación
  @Action(SetOnlineStatus)
  SetOnlineStatus(stateContext: StateContext<SystemStateModel>, action: SetOnlineStatus) {
    stateContext.patchState({
      online: action.online
    });
  }

  // Setea la URL de referencia
  @Action(SetRefererUrl)
  SetRefererUrl(stateContext: StateContext<SystemStateModel>, action: SetRefererUrl) {
    stateContext.patchState({
      refererUrl: action.refererUrl
    });
  }

  // Setea si la aplicación está corriendo en un teléfono móvil nativo
  @Action(SetNativeMobile)
  SetNativeMobile(stateContext: StateContext<SystemStateModel>, action: SetNativeMobile) {
    stateContext.patchState({
      isNativeMobile: action.isNativeMobile
    });
  }
}
