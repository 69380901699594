import { Achievement } from '@docentecas/app/store/achievements/achievements.model';

export class AddActualYearAchievements {
  static readonly type = '[Achievement] Add an Achievement to the actual year list';
  constructor(public action: Achievement) {}
}

export class AddTemporalAchievement {
  static readonly type = '[Achievement] Add an Achievementto the temporal list (aquired)';
  constructor(public action: Achievement) {}
}
