import { fromEvent } from 'rxjs';
import * as i0 from "@angular/core";
var ConnectivityService = /** @class */ (function () {
    function ConnectivityService() {
    }
    ConnectivityService.prototype.online = function () {
        return this.onlineEvent = fromEvent(window, 'online');
    };
    ConnectivityService.prototype.offline = function () {
        return this.offlineEvent = fromEvent(window, 'offline');
    };
    ConnectivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConnectivityService_Factory() { return new ConnectivityService(); }, token: ConnectivityService, providedIn: "root" });
    return ConnectivityService;
}());
export { ConnectivityService };
