export class SetMeetingInfo {
  static readonly type = '[Meeting] Set Meeting Info';
  constructor(
    public id: string,
    public title: string,
    public place: string,
    public initHour: string,
    public endHour: string,
    public fullDate: string,
    public daterimn: string,
    public dateindex: string,
    public description: string,
    public color: string,
    public isCancelled: boolean,
    public rangoCheckInit: number,
    public rangoCheckPost: number,
    public rangoCheckAsistencia: number,
    public latitude: number,
    public longitude: number,
    public pathDescription: string,
  ) {}
}

export class SetCanMarkAttendance {
  static readonly type = '[Meeting] Set Meeting Can Mark Attendance';
  constructor(
    public canMarkAttendance: boolean,
    public actualMeetingStatus: string,
    public actualMeetingStatusColor: string,
    public serverUnixTimestamp: number,
  ) {}
}

export class SetAttendanceMarkHour {
  static readonly type = '[Meeting] Set Meeting Attendance Mark Hour';
  constructor(
    public hourOfMark: string,
  ) {}
}

export class SetAttendanceStatus {
  static readonly type = '[Meeting] Set Attendance Status';
  constructor(
    public isAttendanceMarked: boolean,
    public attendanceTimestamp: number,
  ) {}
}

export class SetReferenceInfo {
  static readonly type = '[Meeting] Set Reference Info';
  constructor(
    public actualReference: string,
  ) {}
}
