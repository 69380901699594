import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { map, tap, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afsAuth: AngularFireAuth,
    private afdb: AngularFireDatabase
  ) { }

  // Se autentica con un token personalizado a Firebase
  public firebaseCustomSignIn(token: string) {
    return this.afsAuth.auth.signInWithCustomToken(token);
  }

  // Obtiene todas las reuniones de hoy
  public getMeetingsByDate(meetingsDate = '') {
    const queryDate = (meetingsDate !== '') ? moment(meetingsDate).format('YYYYMMDD') : moment().format('YYYYMMDD');
    return this.afdb.list('/reuniones', (ref) => ref.orderByChild('dateindex').startAt(queryDate).endAt(queryDate)).snapshotChanges();
  }

  // Obtiene las asistencias de un usuario
  public getAttendancesByUser(nick: string) {
    return this.afdb.list(`/asistencia/byuser/${nick}`).snapshotChanges();
  }

  // Obtiene los logros de un usuario
  public getUserAchievements(nickname: string) {
    return this.afdb.list(`/people/${nickname}/logros`).snapshotChanges();
  }

  // Obtiene el detalle de un logro en particular
  public getAchievement(achievementId: string) {
    return this.afdb.object(`/logros/${achievementId}`).snapshotChanges();
  }

  // Obtiene la asistencia de una reunión en particular
  public getMeetingAttendance(meetingId: string) {
    return this.afdb.list(`/asistencia/byreu/${meetingId}`).snapshotChanges();
  }

  // Obtiene la información de una reunión
  public getMeetingInfo(meetingId: string) {
    return this.afdb.object('/reuniones/' + meetingId).snapshotChanges();
  }

  // Obtiene la lista de presentaciones para una reunión
  public getPresentationsInfo(meetingId: string) {
    return this.afdb.list(`/presentaciones/${meetingId}`).snapshotChanges();
  }

  // Obtiene la información del usuario
  public getUserInfo(nick: string) {
    return this.afdb.object(`/people/${nick}`).snapshotChanges();
  }

  // Obtiene la información actual de la versión
  public getSoftwareVersion() {
    return this.afdb.object('/version/semver_web').snapshotChanges();
  }

  // Marca la asistencia para una reunión
  public markAttendance() {}

  // Obtiene todos los logros actuales del año
  public getActualYearAchievements(year) {
    return this.afdb.list(`/logros/`, ref => ref.orderByChild('year').equalTo(year)).snapshotChanges();
  }

  // Obtiene todos los logros de un usuario de un año
  public getActualYearAchievementsByUser(nickname, year) {
    return this.afdb.list(`/peersonas/${nickname}/logros`, ref => ref.orderByChild('year').equalTo(year)).snapshotChanges();
  }

  // Obtiene todos los archivos adjuntos asociados a un evento
  public getMeetingFiles(meetingId) {
    return this.afdb.list(`/adjuntos/${meetingId}`).snapshotChanges();
  }

  // Marca la asistencia por usuario
  public markAttendanceByMeeting(meetingId: string, nickname: string, attendancecByReu) {
    return this.afdb.object(`/asistencia/byreu/${meetingId}/${nickname}`).set(attendancecByReu);
  }

  // Marca la asistencia por reunion
  public markAttendanceByUser(meetingId: string, nickname: string, markHour) {
    return this.afdb.object(`/asistencia/byuser/${nickname}/${meetingId}`).set(markHour);
  }

  // Comprueba si un usuario ha marcado previamente asistencia en la reunión
  public getUserMeetingAttendance(meetingId: string, nickname: string) {
    return this.afdb.object(`/asistencia/byreu/${meetingId}/${nickname}`).snapshotChanges();
  }

  // Actualiza la información de las personas
  public updatePeopleData(user) {
    return this.afdb.object(`/people/${user.nickname}/casdata`).set(user);
  }

  // Obtiene las valoraciones de una reunión
  public getMeetingValoration(meetingId: string) {
    return this.afdb.list(`/evaluaciones/${meetingId}`).snapshotChanges();
  }

  // Obtiene una valoración de una reunión de un usuario
  public getMeetingUserValoration(meetingId: string, nickname: string) {
    return this.afdb.object(`/evaluaciones/${meetingId}/${nickname}`).snapshotChanges();
  }

  // Obtiene los comentarios de una reunión
  public getMeetingComments(meetingId: string) {
    return this.afdb.list(`/comentarios/${meetingId}`).snapshotChanges();
  }

  // Obtiene los comentarios de una reunión para un usuario
  public getMeetingUserComments(meetingId: string, nickname: string) {
    return this.afdb.list(`/comentarios/${meetingId}/${nickname}`).snapshotChanges();
  }

  // Agrega una nueva valoracion de un usuario
  public addUserMeetingValoration(meetingId: string, nickname: string, data) {
    return this.afdb.object(`/evaluaciones/${meetingId}/${nickname}`).set(data);
  }

  // Agrega un nuevo comentario de un usuario
  public addUserMeetingComment(meetingId: string, id: string, data) {
    return this.afdb.object(`/comentarios/${meetingId}/${id}`).set(data);
  }

  // Obtiene todas las noticias de un año
  public getNewsByYear(year: number) {
    return this.afdb.list(`/noticias/${year}`).snapshotChanges();
  }

  // Obtiene una noticia
  public getNews(year: number, id: string) {
    return this.afdb.object(`/noticias/${year}/${id}`).snapshotChanges();
  }

  // Obtiene la versión
  public getRemoteVersion() {
    return this.afdb.object(`/version`).snapshotChanges();
  }

  // Guarda un logro para un usuario
  public saveUserAchievement(nickname: string, achievementId: string, data) {
    return this.afdb.object(`/people/${nickname}/logros/${achievementId}`).set(data);
  }
}
