// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environment: 'cert',
  production: false,
  auth0: {
    domain: 'alemana-intra-poc.auth0.com',
    clientID: 'IDH04N49LB3bSg9kdiHJld6OonXeoDKl',
    connection: 'adprd',
    audience: 'https://alemana-intra-poc.auth0.com/userinfo',
    responseType: 'token',
    scope: 'openid profile email'
  },
  firebaseFunctionURL: 'https://us-central1-doccasdev.cloudfunctions.net/api/get_professional_custom_token/',
  firebaseFunctionEndpoint: 'https://us-central1-doccasdev.cloudfunctions.net/api/',
  returnUrl: 'https://docentecas-cert.alemana.cl/login',
  firebase: {
    apiKey: 'AIzaSyAO2eQnrMuYzoJcvItmJogDvSdIpthCsAs',
    authDomain: 'doccasdev.firebaseapp.com',
    databaseURL: 'https://doccasdev.firebaseio.com',
    projectId: 'doccasdev',
    storageBucket: 'doccasdev.appspot.com',
    messagingSenderId: '765229760705',
  },
  googleMapsKey: 'AIzaSyBnaM0Opiv3SSoiWsivhsmGQfKKkZNuVKk'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
