import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, LoadingController, MenuController } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Auth0Service } from './services/auth0/auth0.service';
import { StorageService } from './services/storage/storage.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { ConnectivityService } from './services/connectivity/connectivity.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import * as semver from 'semver';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// NGXS
import { Select, Store } from '@ngxs/store';
import { SystemState } from './store/system/system.state';
import { SystemStateModel } from '@docentecas/app/store/system/system.model';
import {
  SetSystemAuthState,
  SetSystemLoadingStatus,
  SetNativeMobile,
  SetSystemLocalVersion,
  SetSystemRemoteVersionInfoAndroid,
  SetSystemRemoteVersionInfoiOS,
  SetSystemRemoteChangelog,
  SetOnlineStatus,
  SetRefererUrl
} from '@docentecas/app/store/system/system.actions';
import {
  AddActualYearAchievements
} from '@docentecas/app/store/achievements/achievements.actions';
import { UserStateModel } from './store/user/user.model';
import {
  SetUserInfo
} from '@docentecas/app/store/user/user.actions';

import Auth0Cordova from '@auth0/cordova';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  @Select(state => state.system) system$: Observable<SystemStateModel>;
  @Select(state => state.user) user$: Observable<UserStateModel>;
  public system;
  public loader;
  public user;

  constructor(
    private firebase: FirebaseService,
    public loading: LoadingController,
    private storage: StorageService,
    private store: Store,
    private router: Router,
    private menu: MenuController,
    private auth0: Auth0Service,
    private connectivity: ConnectivityService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private appVersion: AppVersion
  ) {
    this.initializeApp();

    this.user = this.storage.getUser();
    if (this.user != null) {
      this.store.dispatch(new SetSystemAuthState(true));
      this.store.dispatch(
        new SetUserInfo(
          this.user.user_id,
          this.user.given_name,
          this.user.family_name,
          this.user.nickname,
          this.user.email,
          this.user.department,
          this.user.rut,
          this.user.avatar
        )
      );
      this.menu.enable(true);
      this.router.navigateByUrl('/meetings-list');
    }
  }

  initializeApp() {
    // Cuando la carga de la plataforma está lista
    this.platform.ready().then(() => {

      // Setea en el estado cuando el usuario está en un dispositivo móvil
      if (this.platform.is('cordova')) {
        this.store.dispatch(new SetNativeMobile(true));
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }

      // Deshabilita el menu si no está en MOVIL NATIVO
      if (!this.platform.is('cordova')) {
        this.menu.enable(false);
      }

      // Obtiene la versión actual del software
      this.firebase.getRemoteVersion().subscribe((result: any) => {
        const version = result.payload.val();

        // Actualiza la información respecto a la versión remota
        this.store.dispatch(new SetSystemRemoteVersionInfoAndroid(version.semver_android, version.android_uri));
        this.store.dispatch(new SetSystemRemoteVersionInfoiOS(version.semver_ios, version.ios_uri));
        this.store.dispatch(new SetSystemRemoteChangelog(version.changelog));

        // Si está en un dispositivo móvil, comprueba que
        if (this.platform.is('cordova')) {
          this.checkNewVersion();
        }
      });

      // Cuando retoma conexión
      this.connectivity.online().subscribe(() => {
        const system: SystemStateModel = this.store.selectSnapshot(SystemState);
        if (!system.online) {
          this.menu.enable(true);
          this.store.dispatch(new SetOnlineStatus(true));
          this.store.dispatch(new SetRefererUrl(''));
          return this.router.navigateByUrl(system.refererUrl);
        }
      });

      // Cuando pierde conexión
      this.connectivity.offline().subscribe(() => {
        this.store.dispatch(new SetOnlineStatus(false));
        this.store.dispatch(new SetRefererUrl(this.router.url));
        this.menu.enable(false);
        return this.router.navigateByUrl('connectivity');
      });

      // Redirección de autenticación de Auth0
      (window as any).handleOpenURL = (url: string) => {
        Auth0Cordova.onRedirectUri(url);
      };
    });
  }

  public goToPage(page) {
    this.menu.toggle();
    return this.router.navigateByUrl(page);
  }

  /**
   * Comprueba si la versión remota es mayor a la local
   *
   * @return void
   */
  private checkNewVersion() {
    this.appVersion.getVersionNumber().then((localVersion) => {
      // Setea la versión local
      this.store.dispatch(new SetSystemLocalVersion(localVersion));

      // Comprueba si la versión local es menor a la remota
      const system = this.store.selectSnapshot(SystemState);

      // Obtiene la versión basado en que tipo de plataforma es
      let remoteVersion: string;
      if (this.platform.is('android')) {
        remoteVersion = system.remoteAndroidVersion;
      } else {
        remoteVersion = system.remoteiOSVersion;
      }

      // Si la versión remota es mayor, navega a la actualización
      if (semver.gte(remoteVersion, localVersion)) {
        this.router.navigateByUrl('/update');
      }
    }, (error) => {
      console.error('Ocurrió un error al obtener la versión local de la aplicación');
    });
  }

  /**
   * Cierra el menu
   *
   * @return void
   */
  public closeMenu() {
    return this.menu.toggle();
  }

  /**
   * Realiza el logout de auth0 y elimina el sessionStorage
   *
   * @return void
   */
  public logout() {
    this.storage.clearSession();
    this.auth0.logout();
  }
}
