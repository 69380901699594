import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { MeetingStateModel } from './meeting.model';
import {
  SetMeetingInfo,
  SetCanMarkAttendance,
  SetAttendanceStatus,
  SetReferenceInfo,
  SetAttendanceMarkHour
} from './meeting.actions';

@State({
  name: 'meeting',
  defaults: {
    id: '',
    title: '',
    place: '',
    initHour: '',
    endHour: '',
    fullDate: '',
    daterimn: '',
    dateindex: '',
    color: '',
    description: '',
    isCancelled: false,
    canMarkAttendance: false,
    rangoCheckInit: 0,
    rangoCheckPost: 0,
    rangoCheckAsistencia: 0,
    actualMeetingStatus: '',
    actualMeetingStatusColor: '',
    latitude: 0,
    longitude: 0,
    pathDescription: '',
    isAttendanceMarked: false,
    attendanceTimestamp: 0,
    actualReference: '',
    serverUnixTimestamp: 0,
    hourOfMark: '',
  }
})
export class MeetingState {

  // Obtiene toda la información de la reunión en el estado
  @Selector()
  static meeting(state: MeetingStateModel) { return state; }

  // Obtiene información si la reunión activa se encuentra
  @Selector()
  static reference(state: MeetingStateModel) { return state.actualReference; }

  // Setea la información de la reunión
  @Action(SetMeetingInfo)
  SetMeetingInfo(stateContext: StateContext<MeetingStateModel>, action: SetMeetingInfo) {
    stateContext.patchState({
      id: action.id,
      title: action.title,
      place: action.place,
      initHour: action.initHour,
      endHour: action.endHour,
      fullDate: action.fullDate,
      daterimn: action.daterimn,
      dateindex: action.dateindex,
      color: action.color,
      description: action.description,
      isCancelled: action.isCancelled,
      rangoCheckInit: action.rangoCheckInit,
      rangoCheckPost: action.rangoCheckPost,
      rangoCheckAsistencia: action.rangoCheckAsistencia,
      latitude: action.latitude,
      longitude: action.longitude,
      pathDescription: action.pathDescription,
    });
  }

  // Setea la información de la reunión
  @Action(SetCanMarkAttendance)
  SetCanMarkAttendance(stateContext: StateContext<MeetingStateModel>, action: SetCanMarkAttendance) {
    stateContext.patchState({
      canMarkAttendance: action.canMarkAttendance,
      actualMeetingStatus: action.actualMeetingStatus,
      actualMeetingStatusColor: action.actualMeetingStatusColor,
      serverUnixTimestamp: action.serverUnixTimestamp,
    });
  }

  // Setea la información de marca de asistencia para esta reunión
  @Action(SetAttendanceStatus)
  SetAttendanceStatus(stateContext: StateContext<MeetingStateModel>, action: SetAttendanceStatus) {
    stateContext.patchState({
      isAttendanceMarked: action.isAttendanceMarked,
      attendanceTimestamp: action.attendanceTimestamp
    });
  }

  // Setea la hora de marca de la reunión
  @Action(SetAttendanceMarkHour)
  SetAttendanceMarkHour(stateContext: StateContext<MeetingStateModel>, action: SetAttendanceMarkHour) {
    stateContext.patchState({
      hourOfMark: action.hourOfMark
    });
  }


  // Setea la información de referencia de la reunión
  @Action(SetReferenceInfo)
  SetReferenceInfo({ patchState}: StateContext<MeetingStateModel>, action: SetReferenceInfo) {
    patchState({
      actualReference: action.actualReference
    });
  }

}
