import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/fire/database";
var FirebaseService = /** @class */ (function () {
    function FirebaseService(afsAuth, afdb) {
        this.afsAuth = afsAuth;
        this.afdb = afdb;
    }
    // Se autentica con un token personalizado a Firebase
    FirebaseService.prototype.firebaseCustomSignIn = function (token) {
        return this.afsAuth.auth.signInWithCustomToken(token);
    };
    // Obtiene todas las reuniones de hoy
    FirebaseService.prototype.getMeetingsByDate = function (meetingsDate) {
        if (meetingsDate === void 0) { meetingsDate = ''; }
        var queryDate = (meetingsDate !== '') ? moment(meetingsDate).format('YYYYMMDD') : moment().format('YYYYMMDD');
        return this.afdb.list('/reuniones', function (ref) { return ref.orderByChild('dateindex').startAt(queryDate).endAt(queryDate); }).snapshotChanges();
    };
    // Obtiene las asistencias de un usuario
    FirebaseService.prototype.getAttendancesByUser = function (nick) {
        return this.afdb.list("/asistencia/byuser/" + nick).snapshotChanges();
    };
    // Obtiene los logros de un usuario
    FirebaseService.prototype.getUserAchievements = function (nickname) {
        return this.afdb.list("/people/" + nickname + "/logros").snapshotChanges();
    };
    // Obtiene el detalle de un logro en particular
    FirebaseService.prototype.getAchievement = function (achievementId) {
        return this.afdb.object("/logros/" + achievementId).snapshotChanges();
    };
    // Obtiene la asistencia de una reunión en particular
    FirebaseService.prototype.getMeetingAttendance = function (meetingId) {
        return this.afdb.list("/asistencia/byreu/" + meetingId).snapshotChanges();
    };
    // Obtiene la información de una reunión
    FirebaseService.prototype.getMeetingInfo = function (meetingId) {
        return this.afdb.object('/reuniones/' + meetingId).snapshotChanges();
    };
    // Obtiene la lista de presentaciones para una reunión
    FirebaseService.prototype.getPresentationsInfo = function (meetingId) {
        return this.afdb.list("/presentaciones/" + meetingId).snapshotChanges();
    };
    // Obtiene la información del usuario
    FirebaseService.prototype.getUserInfo = function (nick) {
        return this.afdb.object("/people/" + nick).snapshotChanges();
    };
    // Obtiene la información actual de la versión
    FirebaseService.prototype.getSoftwareVersion = function () {
        return this.afdb.object('/version/semver_web').snapshotChanges();
    };
    // Marca la asistencia para una reunión
    FirebaseService.prototype.markAttendance = function () { };
    // Obtiene todos los logros actuales del año
    FirebaseService.prototype.getActualYearAchievements = function (year) {
        return this.afdb.list("/logros/", function (ref) { return ref.orderByChild('year').equalTo(year); }).snapshotChanges();
    };
    // Obtiene todos los logros de un usuario de un año
    FirebaseService.prototype.getActualYearAchievementsByUser = function (nickname, year) {
        return this.afdb.list("/peersonas/" + nickname + "/logros", function (ref) { return ref.orderByChild('year').equalTo(year); }).snapshotChanges();
    };
    // Obtiene todos los archivos adjuntos asociados a un evento
    FirebaseService.prototype.getMeetingFiles = function (meetingId) {
        return this.afdb.list("/adjuntos/" + meetingId).snapshotChanges();
    };
    // Marca la asistencia por usuario
    FirebaseService.prototype.markAttendanceByMeeting = function (meetingId, nickname, attendancecByReu) {
        return this.afdb.object("/asistencia/byreu/" + meetingId + "/" + nickname).set(attendancecByReu);
    };
    // Marca la asistencia por reunion
    FirebaseService.prototype.markAttendanceByUser = function (meetingId, nickname, markHour) {
        return this.afdb.object("/asistencia/byuser/" + nickname + "/" + meetingId).set(markHour);
    };
    // Comprueba si un usuario ha marcado previamente asistencia en la reunión
    FirebaseService.prototype.getUserMeetingAttendance = function (meetingId, nickname) {
        return this.afdb.object("/asistencia/byreu/" + meetingId + "/" + nickname).snapshotChanges();
    };
    // Actualiza la información de las personas
    FirebaseService.prototype.updatePeopleData = function (user) {
        return this.afdb.object("/people/" + user.nickname + "/casdata").set(user);
    };
    // Obtiene las valoraciones de una reunión
    FirebaseService.prototype.getMeetingValoration = function (meetingId) {
        return this.afdb.list("/evaluaciones/" + meetingId).snapshotChanges();
    };
    // Obtiene una valoración de una reunión de un usuario
    FirebaseService.prototype.getMeetingUserValoration = function (meetingId, nickname) {
        return this.afdb.object("/evaluaciones/" + meetingId + "/" + nickname).snapshotChanges();
    };
    // Obtiene los comentarios de una reunión
    FirebaseService.prototype.getMeetingComments = function (meetingId) {
        return this.afdb.list("/comentarios/" + meetingId).snapshotChanges();
    };
    // Obtiene los comentarios de una reunión para un usuario
    FirebaseService.prototype.getMeetingUserComments = function (meetingId, nickname) {
        return this.afdb.list("/comentarios/" + meetingId + "/" + nickname).snapshotChanges();
    };
    // Agrega una nueva valoracion de un usuario
    FirebaseService.prototype.addUserMeetingValoration = function (meetingId, nickname, data) {
        return this.afdb.object("/evaluaciones/" + meetingId + "/" + nickname).set(data);
    };
    // Agrega un nuevo comentario de un usuario
    FirebaseService.prototype.addUserMeetingComment = function (meetingId, id, data) {
        return this.afdb.object("/comentarios/" + meetingId + "/" + id).set(data);
    };
    // Obtiene todas las noticias de un año
    FirebaseService.prototype.getNewsByYear = function (year) {
        return this.afdb.list("/noticias/" + year).snapshotChanges();
    };
    // Obtiene una noticia
    FirebaseService.prototype.getNews = function (year, id) {
        return this.afdb.object("/noticias/" + year + "/" + id).snapshotChanges();
    };
    // Obtiene la versión
    FirebaseService.prototype.getRemoteVersion = function () {
        return this.afdb.object("/version").snapshotChanges();
    };
    // Guarda un logro para un usuario
    FirebaseService.prototype.saveUserAchievement = function (nickname, achievementId, data) {
        return this.afdb.object("/people/" + nickname + "/logros/" + achievementId).set(data);
    };
    FirebaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseService_Factory() { return new FirebaseService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.AngularFireDatabase)); }, token: FirebaseService, providedIn: "root" });
    return FirebaseService;
}());
export { FirebaseService };
