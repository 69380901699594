import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Platform, LoadingController, MenuController } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Auth0Service } from './services/auth0/auth0.service';
import { StorageService } from './services/storage/storage.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { ConnectivityService } from './services/connectivity/connectivity.service';
import { Observable } from 'rxjs';
import * as semver from 'semver';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// NGXS
import { Select, Store } from '@ngxs/store';
import { SystemState } from './store/system/system.state';
import { SetSystemAuthState, SetNativeMobile, SetSystemLocalVersion, SetSystemRemoteVersionInfoAndroid, SetSystemRemoteVersionInfoiOS, SetSystemRemoteChangelog, SetOnlineStatus, SetRefererUrl } from '@docentecas/app/store/system/system.actions';
import { SetUserInfo } from '@docentecas/app/store/user/user.actions';
import Auth0Cordova from '@auth0/cordova';
var ɵ0 = function (state) { return state.system; }, ɵ1 = function (state) { return state.user; };
var AppComponent = /** @class */ (function () {
    function AppComponent(firebase, loading, storage, store, router, menu, auth0, connectivity, platform, splashScreen, statusBar, appVersion) {
        this.firebase = firebase;
        this.loading = loading;
        this.storage = storage;
        this.store = store;
        this.router = router;
        this.menu = menu;
        this.auth0 = auth0;
        this.connectivity = connectivity;
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.appVersion = appVersion;
        this.initializeApp();
        this.user = this.storage.getUser();
        if (this.user != null) {
            this.store.dispatch(new SetSystemAuthState(true));
            this.store.dispatch(new SetUserInfo(this.user.user_id, this.user.given_name, this.user.family_name, this.user.nickname, this.user.email, this.user.department, this.user.rut, this.user.avatar));
            this.menu.enable(true);
            this.router.navigateByUrl('/meetings-list');
        }
    }
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        // Cuando la carga de la plataforma está lista
        this.platform.ready().then(function () {
            // Setea en el estado cuando el usuario está en un dispositivo móvil
            if (_this.platform.is('cordova')) {
                _this.store.dispatch(new SetNativeMobile(true));
                _this.statusBar.styleDefault();
                _this.splashScreen.hide();
            }
            // Deshabilita el menu si no está en MOVIL NATIVO
            if (!_this.platform.is('cordova')) {
                _this.menu.enable(false);
            }
            // Obtiene la versión actual del software
            _this.firebase.getRemoteVersion().subscribe(function (result) {
                var version = result.payload.val();
                // Actualiza la información respecto a la versión remota
                _this.store.dispatch(new SetSystemRemoteVersionInfoAndroid(version.semver_android, version.android_uri));
                _this.store.dispatch(new SetSystemRemoteVersionInfoiOS(version.semver_ios, version.ios_uri));
                _this.store.dispatch(new SetSystemRemoteChangelog(version.changelog));
                // Si está en un dispositivo móvil, comprueba que
                if (_this.platform.is('cordova')) {
                    _this.checkNewVersion();
                }
            });
            // Cuando retoma conexión
            _this.connectivity.online().subscribe(function () {
                var system = _this.store.selectSnapshot(SystemState);
                if (!system.online) {
                    _this.menu.enable(true);
                    _this.store.dispatch(new SetOnlineStatus(true));
                    _this.store.dispatch(new SetRefererUrl(''));
                    return _this.router.navigateByUrl(system.refererUrl);
                }
            });
            // Cuando pierde conexión
            _this.connectivity.offline().subscribe(function () {
                _this.store.dispatch(new SetOnlineStatus(false));
                _this.store.dispatch(new SetRefererUrl(_this.router.url));
                _this.menu.enable(false);
                return _this.router.navigateByUrl('connectivity');
            });
            // Redirección de autenticación de Auth0
            window.handleOpenURL = function (url) {
                Auth0Cordova.onRedirectUri(url);
            };
        });
    };
    AppComponent.prototype.goToPage = function (page) {
        this.menu.toggle();
        return this.router.navigateByUrl(page);
    };
    /**
     * Comprueba si la versión remota es mayor a la local
     *
     * @return void
     */
    AppComponent.prototype.checkNewVersion = function () {
        var _this = this;
        this.appVersion.getVersionNumber().then(function (localVersion) {
            // Setea la versión local
            _this.store.dispatch(new SetSystemLocalVersion(localVersion));
            // Comprueba si la versión local es menor a la remota
            var system = _this.store.selectSnapshot(SystemState);
            // Obtiene la versión basado en que tipo de plataforma es
            var remoteVersion;
            if (_this.platform.is('android')) {
                remoteVersion = system.remoteAndroidVersion;
            }
            else {
                remoteVersion = system.remoteiOSVersion;
            }
            // Si la versión remota es mayor, navega a la actualización
            if (semver.gte(remoteVersion, localVersion)) {
                _this.router.navigateByUrl('/update');
            }
        }, function (error) {
            console.error('Ocurrió un error al obtener la versión local de la aplicación');
        });
    };
    /**
     * Cierra el menu
     *
     * @return void
     */
    AppComponent.prototype.closeMenu = function () {
        return this.menu.toggle();
    };
    /**
     * Realiza el logout de auth0 y elimina el sessionStorage
     *
     * @return void
     */
    AppComponent.prototype.logout = function () {
        this.storage.clearSession();
        this.auth0.logout();
    };
    tslib_1.__decorate([
        Select(ɵ0),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "system$", void 0);
    tslib_1.__decorate([
        Select(ɵ1),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "user$", void 0);
    return AppComponent;
}());
export { AppComponent };
export { ɵ0, ɵ1 };
