import { State, Selector, Store, StateContext, Action } from '@ngxs/store';
import { NewsStateModel } from './news.model';
import {
  AddNew
} from './news.actions';

@State<NewsStateModel>({
  name: 'news',
  defaults: {
    id: '',
    extracto: '',
    fecha: '',
    fechashort: 0,
    imagen: '',
    texto: '',
    titulo: '',
    url_noticia: '',
  }
})
export class NewsState {

  // Obtiene la noticia actual
  @Selector()
  static presentators(state: NewsStateModel ) { return state; }

  // Agrega una noticia al state
  @Action(AddNew)
  AddNew({ patchState }: StateContext<NewsStateModel>, { action }: AddNew) {
    patchState(action);
  }
}
