import * as tslib_1 from "tslib";
import { State, Selector, Action } from '@ngxs/store';
import { SetMeetingInfo, SetCanMarkAttendance, SetAttendanceStatus, SetReferenceInfo, SetAttendanceMarkHour } from './meeting.actions';
var MeetingState = /** @class */ (function () {
    function MeetingState() {
    }
    // Obtiene toda la información de la reunión en el estado
    MeetingState.meeting = function (state) { return state; };
    // Obtiene información si la reunión activa se encuentra
    MeetingState.reference = function (state) { return state.actualReference; };
    // Setea la información de la reunión
    MeetingState.prototype.SetMeetingInfo = function (stateContext, action) {
        stateContext.patchState({
            id: action.id,
            title: action.title,
            place: action.place,
            initHour: action.initHour,
            endHour: action.endHour,
            fullDate: action.fullDate,
            daterimn: action.daterimn,
            dateindex: action.dateindex,
            color: action.color,
            description: action.description,
            isCancelled: action.isCancelled,
            rangoCheckInit: action.rangoCheckInit,
            rangoCheckPost: action.rangoCheckPost,
            rangoCheckAsistencia: action.rangoCheckAsistencia,
            latitude: action.latitude,
            longitude: action.longitude,
            pathDescription: action.pathDescription,
        });
    };
    // Setea la información de la reunión
    MeetingState.prototype.SetCanMarkAttendance = function (stateContext, action) {
        stateContext.patchState({
            canMarkAttendance: action.canMarkAttendance,
            actualMeetingStatus: action.actualMeetingStatus,
            actualMeetingStatusColor: action.actualMeetingStatusColor,
            serverUnixTimestamp: action.serverUnixTimestamp,
        });
    };
    // Setea la información de marca de asistencia para esta reunión
    MeetingState.prototype.SetAttendanceStatus = function (stateContext, action) {
        stateContext.patchState({
            isAttendanceMarked: action.isAttendanceMarked,
            attendanceTimestamp: action.attendanceTimestamp
        });
    };
    // Setea la hora de marca de la reunión
    MeetingState.prototype.SetAttendanceMarkHour = function (stateContext, action) {
        stateContext.patchState({
            hourOfMark: action.hourOfMark
        });
    };
    // Setea la información de referencia de la reunión
    MeetingState.prototype.SetReferenceInfo = function (_a, action) {
        var patchState = _a.patchState;
        patchState({
            actualReference: action.actualReference
        });
    };
    tslib_1.__decorate([
        Action(SetMeetingInfo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetMeetingInfo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState.prototype, "SetMeetingInfo", null);
    tslib_1.__decorate([
        Action(SetCanMarkAttendance),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetCanMarkAttendance]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState.prototype, "SetCanMarkAttendance", null);
    tslib_1.__decorate([
        Action(SetAttendanceStatus),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetAttendanceStatus]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState.prototype, "SetAttendanceStatus", null);
    tslib_1.__decorate([
        Action(SetAttendanceMarkHour),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetAttendanceMarkHour]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState.prototype, "SetAttendanceMarkHour", null);
    tslib_1.__decorate([
        Action(SetReferenceInfo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetReferenceInfo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState.prototype, "SetReferenceInfo", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState, "meeting", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MeetingState, "reference", null);
    MeetingState = tslib_1.__decorate([
        State({
            name: 'meeting',
            defaults: {
                id: '',
                title: '',
                place: '',
                initHour: '',
                endHour: '',
                fullDate: '',
                daterimn: '',
                dateindex: '',
                color: '',
                description: '',
                isCancelled: false,
                canMarkAttendance: false,
                rangoCheckInit: 0,
                rangoCheckPost: 0,
                rangoCheckAsistencia: 0,
                actualMeetingStatus: '',
                actualMeetingStatusColor: '',
                latitude: 0,
                longitude: 0,
                pathDescription: '',
                isAttendanceMarked: false,
                attendanceTimestamp: 0,
                actualReference: '',
                serverUnixTimestamp: 0,
                hourOfMark: '',
            }
        })
    ], MeetingState);
    return MeetingState;
}());
export { MeetingState };
