import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { LoaderComponent } from '@docentecas/app/components/loader/loader.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Auth0Service } from './services/auth0/auth0.service';
import { ApiService } from './services/api/api.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { HttpClientModule } from '@angular/common/http';

// NGXS
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { SystemState } from '@docentecas/app/store/system/system.state';
import { MeetingState } from './store/meeting/meeting.state';
import { UserState } from './store/user/user.state';
import { PresentatorsState } from './store/presentators/presentators.state';
import { AchievementsState } from './store/achievements/achievements.state';
import { AttendancesState } from './store/attendances/attendances.state';
import { NewsState } from './store/news/news.state';

// AngularFire
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Cordova (Native)
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      hardwareBackButton: false,
    }),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase, 'docentecas-web'),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    NgxsModule.forRoot([
      SystemState,
      MeetingState,
      UserState,
      PresentatorsState,
      AchievementsState,
      AttendancesState,
      NewsState
    ], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsResetPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Auth0Service,
    ApiService,
    FirebaseService,
    AppVersion,
    FirebaseMessaging,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
